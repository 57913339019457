import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@views/index"),
    alias: '/index.html',
    children: [
      {
        path: "/",   //首页
        name: "home", 
        component: () => import("@views/home/home"),
      },
      {
        path: "/choicePhone",//选择机型
        name: "choicePhone", 
        component: () => import("@views/choicePhone/phone"),
      },
      {
        path: "/phone",//选择机型
        name: "phone", 
        alias: '/phone.html',
        component: () => import("@views/choicePhone/phone"),
      },
      {
        path: "/ipad", // 手机维修选择机型
        name: "ipad", 
        alias: ['/ipad.html', '/pad.html'],
        component: () => import("@views/choicePhone/ipad"),
      },
      {
        path: "/computer",
        name: "computer",
        alias: ["/laptop", "/laptop.html"],
        component: () => import("@views/choicePhone/computer"),
      },
      {
        path: "/choiceFault", //选择故障
        name: "choiceFault",
        component: () => import("@views/choiceFault/choiceFault"),
      },
      {
        path: "/confirmOrder", //确认订单
        name: "confirmOrder",
        component: () => import("@views/confirmOrder/confirmOrder"),
      },
      {
        path: "/placeOrderSuccess", //预约成功
        name: "placeOrderSuccess",
        component: () => import("@views/placeOrderSuccess/placeOrderSuccess"),
      },
      {
        path: "/informationDetail", //资讯详情
        name: "informationDetail",
        component: () => import("@views/informationDetail/informationDetail"),
      },
      {
        path: "/serviceCentre", //服务中心
        name: "serviceCentre",
        component: () => import("@views/serviceCentre/serviceCentre"),
      },
      {
        path: "/aboutSxx", //关于闪修侠
        name: "aboutSxx",
        component: () => import("@views/aboutSxx/aboutSxx"),
        children: [
          {
            path: "/aboutUs", //关于我们
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/aboutUs"),
            alias: '/aboutUs.html'
          },
          {
            path: "/safeguard", //服务保障
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/safeguard"),
          },
          {
            path: "/settled", //服务商入驻
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/settled"),
          },
          {
            path: "/clause", //服务条款
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/clause"),
          },
          {
            path: "/develop", //发展历程
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/develop"),
          },
          {
            path: "/recruit", //招贤纳士
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/recruit"),
          },      {
            path: "/dynamic", //行业动态
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/dynamic"),
          },
          {
            path: "/contactUs", //联系我们
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/contactUs"),
          },
          {
            path: "/cooperate", //合作伙伴
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/cooperate"),
          },
          {
            path: "/problem", //常见问题
            name: "aboutSxx",
            component: () => import("@views/aboutSxx/component/problem"),
          }
        ]
      },
      {
        path: "/enter", // 招商加盟页，
        name: 'enter',
        alias: '/enter.html',
        component: () => import("@views/enter/enter"),
      },
      {
        path: '/entersubmit',
        name: 'submit',
        component: () => import("@views/enter/submit")
      },
      {
        path: '/enter/success/:id',
        name: 'success',
        component: () => import("@views/enter/success")
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((e) => {
  if(e.name !='aboutSxx'){
    window.scrollTo(0, 0);
    document.querySelector("body").setAttribute("style", "overflow: auto !important;");
  }
});

router.onError((error) => {
  const pattern =/Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if(isChunkLoadFailed) {
    router.replace(targetPath);
  }
})
export default router;
