import { generateUUID, isEmpty } from '@/utils/util'
import urlBase from '@/utils/url'
import request from '@/utils/request'

const pubUrl = urlBase.pubUrl
/**
 * 记录访客访问行为
 * @param {String} path 当前页面的路径
*/
export function createLog(path) {
    let source = 35
    // 获取用户信息
	let phone = localStorage.getItem('mobile') || ''
    // 获取本地存储的uuid
	let uuid = localStorage.getItem('PAGE_UUID')
	if (isEmpty(uuid)) {
		uuid = generateUUID()
		localStorage.setItem('PAGE_UUID', uuid)
	}
    const data = {
		uuid,
		cellphone: phone,
        origin: 4,
		source,
		access_route: path
	}
    return new Promise((resolve, reject) => {
        request({
            url: `${pubUrl}/client-client-api/client/home/statistical/access`,
            method: 'post',
            data
        }).then(res => {
            if (res && res.code === 200) {
                resolve(res)
            } else {
                resolve(null)
            }
        }).catch(err => {
            console.log(err);
            resolve(null)
        })
    })
}