import axios from 'axios';
import { pubUrl, timeout } from './config';
const instance = axios.create({
  baseURL: pubUrl,
  timeout
});
axios.defaults.headers['Content-Type'] = 'application/json';  //设置默认提交 json
axios.defaults.transformRequest = data => JSON.stringify(data);   //把数据对象序列化成 json字符串
axios.defaults.withCredentials=false;

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    token && (config.headers["Authorization"] = token);
    return config;
  },
  (err) => {
    console.error(err);
  }
);
// respone拦截器
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
 (err) => {
  if (err && err.response) {
    switch (err.response.code) {
      case 400:
        console.log("请求错误");
        break;
      case 401:
        console.log("未授权访问");
        break;
      default:
        console.log("其他错误信息");
    }
  }
  return err;
});

export default instance;

