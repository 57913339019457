import router from './router'
import { getEnumList } from '@/utils/util'
import { createLog } from '@/methods/business'

const tabEnum = {
    home: {
        arr: ['/', '/index', '/index.html'],
        index: 0
    },
    phone: {
        arr: ['/phone', '/phone.html', '/phone/', '/choicePhone?category_id=1&type_id=9999'],
        index: 1
    },
    ipad: {
        arr: ['/ipad', '/ipad.html', '/ipad/', '/pad.html', '/choicePhone?category_id=1&type_id=7'],
        index: 2
    },
    computer: {
        arr: ['/computer', '/laptop.html', '/laptop/', '/choicePhone?category_id=4&type_id=9999'],
        index: 3
    },
    enter: {
        arr: ['/enter', '/enter/', '/enter.html'],
        index: 4
    },
    aboutUs: {
        arr: ['/aboutUs', '/aboutUs.html', '/aboutUs/'],
        index: 5
    },
    getList() {
        return getEnumList(tabEnum)
    }
}

/**
 * 路由导航
*/
router.beforeEach((to, from, next) => {
    // 对符合条件的，tab栏位置响应
    let pathArr = tabEnum.getList()
    let index = 0
    pathArr.some(item => {
        if (item.arr.includes(to.fullPath)) {
            index = item.index
            return true
        }
    })
    localStorage.setItem("navIndex", index);
    
    next()
})

/**
 * 路由拦截后置
*/
router.afterEach((to, from) => {
    createLog(to.fullPath)
})