let pubUrl,adminUrl,innerUrl,pubCdnUrl;
if(process.env.NODE_ENV === 'production'){ //生产环境
  pubUrl = 'https://pub-api.shanxiuxia.com';
  adminUrl = 'https://admin.shanxiuxia.com';
  innerUrl = 'https://inner-api-resolved.shanxiuxia.com';
  pubCdnUrl = 'https://pub-api-cdn.shanxiuxia.com';
}else{ //测试环境 
  pubUrl = 'https://pub-api-test.shanxiuxia.com';
  adminUrl = 'https://tadmin.shanxiuxia.com';
  innerUrl = 'https://inner-api-resolved-test.shanxiuxia.com';
  pubCdnUrl = 'https://pub-api-test-cdn.shanxiuxia.com';
}

const base = {
  entrance: 10,
  pubUrl: pubUrl,
  adminUrl:adminUrl,
  innerUrl:innerUrl,
  pubCdnUrl:pubCdnUrl,
}
export default base